
.dropdown {
    width: 130px;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0,0,0,.1);
    background-color: white;
    position: relative;

}
.dropdown-header {
    border-radius: 10px;
}

.dropdown-header.open {
    border-radius: 10px 10px 0px 0px;
}

.dropdown-header {
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to left, #00164e 32%, rgba(255, 255, 255, 0.0) 20%);
}
.dropdown-label{
    color: #00164e;
    font-size: 10px!important;
}
.dropdown-body {
    padding: 5px;
    border-top: 1px solid #E5E8EC;
    display: none;
}

.dropdown-body.open {
    display: block;
    /*position: fixed;*/
    z-index: 1000;
}

.dropdown-item {
    padding: 10px;
    color: #00164e;

}
.dropdown-item-label{
    font-size: 10px!important;
}
.dropdown-item:hover {
    color: #91A5BE;
    cursor: pointer;
}

.dropdown-item-dot {
    opacity: 0;
    color: #91A5BE;
    transition: all .2s ease-in-out;
}

.dropdown-item-dot.selected {
    opacity: 1;
}

.icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
}

.icon.open {
    transform: rotate(90deg);
}

.dropdown-icon{
    background-color: #00164e;
    color: white;
    font-size: 10px!important;
}

.dropdown-icon .fa-solid{
    background-color: #00164e;
    color: white;
    font-size: 10px!important;
}